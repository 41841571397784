@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-LightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-Italic.ttf') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-BoldItalic.ttf') format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Mulish';
    src: url('/fonts/Mulish-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
  }

  :root {
    /* EVgo brand colors */
    --ev-dark-blue: 0 42 57;
    --ev-light-blue: 31 163 255;
    --ev-gray: 61 65 70;
    --ev-yellow: 255 196 37;

    /* Theme intent */
    /* Text colors */
    --color-text-base: var(--ev-dark-blue);
    --color-text-secondary: var(--ev-gray);
    --color-text-link: var(--ev-light-blue);
    --color-text-button: var(--ev-dark-blue);
    /* Background colors */
    --color-fill: 255 255 255;
    --color-button-fill: var(--ev-yellow);
  }
  
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'Mulish', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
      Helvetica Neue, sans-serif;
  }
  
  .underline {
    text-decoration: underline;
    color: #1FA3FF;
  }

   /* When adding a new theme, add theme to useThemeClass hook */
  .theme-subaru {
    --color-button-fill: 32 96 159;
    --color-text-button: 255 255 255;
  }
}
